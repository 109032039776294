























































import { Component, Vue } from 'vue-property-decorator';
import { ICreateEfectivo } from '@/interfaces';
import { dispatchGetOneCobrosEfectivo, dispatchUpdateCobroEfectivo, dispatchGetUsers } from '@/store/admin/actions';
import { readAdminUsers } from '@/store/admin/getters';

@Component
export default class EditCobroEfectivo extends Vue {
  public showUserError = false;
  public show = false;
  public valid = true;
  public nombre = '';
  public descripcion = '';
  public importe = 0;
  public nif = '';
  public direccion = '';
  public userId = 0;
  public data() {
    return {
      rules: {
        required: (value) => !!value || 'Obligaratorio.',
        counter: (value) => value.length <= 6 || 'Max 6 caracteres',
      },
    };
  }

  public async created() {
    await dispatchGetUsers(this.$store);
    await this.reset();
    this.show = true;
  }

  public async reset() {

    this.$validator.reset();
    const cobro = await dispatchGetOneCobrosEfectivo(this.$store, +this.$router.currentRoute.params.id);

    if (cobro) {
      this.nombre = cobro.nombre!;
      this.descripcion = cobro.descripcion!;
      this.importe = cobro.importe!;
      this.userId = cobro.user_id!;
      this.nif = cobro.nif!;
      this.direccion = cobro.direccion!;

    }
  }

  public cancel() {
    this.$router.back();
  }

  public async submit() {
    if (await this.$validator.validateAll()) {
      if (this.userId === 0) {
        this.showUserError = true;
      } else {
        const updatedProfile: ICreateEfectivo = {
          user_id: this.userId, id: +this.$router.currentRoute.params.id
        };
        this.showUserError = false;
        if (this.nombre) {
          updatedProfile.nombre = this.nombre;
        }
        if (this.descripcion) {
          updatedProfile.descripcion = this.descripcion;
        }
        if (this.importe) {
          updatedProfile.importe = this.importe;
        }
        if (this.nif) {
          updatedProfile.nif = this.nif;
        }
        if (this.direccion) {
          updatedProfile.direccion = this.direccion;
        }

        await dispatchUpdateCobroEfectivo(this.$store, updatedProfile);
        this.$router.push('/main/admin/cobrosefectivo/all');
      }
    }
  }


  get users() {
    return readAdminUsers(this.$store);
  }
}
