










































































































import { Component, Vue } from 'vue-property-decorator';
import { IUserProfile, IUserProfileUpdate } from '@/interfaces';
import { dispatchGetUsers, dispatchUpdateUser } from '@/store/admin/actions';
import { readAdminOneUser } from '@/store/admin/getters';

@Component
export default class EditUser extends Vue {
  public valid = true;
  public fullName: string = '';
  public email: string = '';
  public isActive: boolean = true;
  public isSuperuser: boolean = false;
  public setPassword = false;
  public password1: string = '';
  public password2: string = '';
  public codigoPropietario: string = '';
  public codigoInquilino: string = '';
  public data() {
    return {
      rules: {
        required: (value) => !!value || 'Obligaratorio.',
        counter: (value) => value.length <= 6 || 'Max 6 caracteres',
      },
    };
  }

  public async mounted() {
    await dispatchGetUsers(this.$store);
    this.reset();
  }

  public reset() {
    this.setPassword = false;
    this.password1 = '';
    this.password2 = '';

    this.$validator.reset();
    if (this.user) {
      this.fullName = this.user.nombre;
      this.email = this.user.email;
      this.isActive = this.user.is_active;
      this.isSuperuser = this.user.is_super;
      this.codigoPropietario = this.user.codigo_propietario;
      this.codigoInquilino = this.user.codigo_inquilino;
    }
  }

  public cancel() {
    this.$router.back();
  }

  public async submit() {
    if (await this.$validator.validateAll()) {
      const updatedProfile: IUserProfileUpdate = {};
      if (this.fullName) {
        updatedProfile.nombre = this.fullName;
      }
      if (this.email) {
        updatedProfile.email = this.email;
      }
      if (this.codigoPropietario) {
        updatedProfile.codigo_propietario = this.codigoPropietario;
      }
      if (this.codigoInquilino) {
        updatedProfile.codigo_inquilino = this.codigoInquilino;
      }
      updatedProfile.is_active = this.isActive;
      updatedProfile.is_super = this.isSuperuser;
      if (this.setPassword) {
        updatedProfile.password = this.password1;
      }
      await dispatchUpdateUser(this.$store, {
        id: this.user!.id,
        user: updatedProfile,
      });
      this.$router.push('/main/admin/users');
    }
  }

  get user() {
    return readAdminOneUser(this.$store)(+this.$router.currentRoute.params.id);
  }
}
